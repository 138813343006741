var mena = window.mena || {};

(function ($) {
    'use strict';

    mena.main.init = function () {
        //mena.main.stickyHeader();
        mena.main.animations();
        mena.main.mobileMenu();
        mena.main.svg4everybody();
        mena.main.getNews();
        mena.main.loadAssets();
        mena.main.news();
        mena.main.accordions();
        mena.main.filter();
        mena.main.allRallies();
        mena.main.gallery();
        mena.main.copyToClipboard();
    };

    mena.main.accordions = function () {
        /* SLIDE UP */

        let slideUp = (target, duration = 500) => {
            target.style.transitionProperty = 'height, margin, padding';
            target.style.transitionDuration = duration + 'ms';
            target.style.boxSizing = 'border-box';
            target.style.height = target.offsetHeight + 'px';
            target.offsetHeight;
            target.style.overflow = 'hidden';
            target.style.height = 0;
            target.style.paddingTop = 0;
            target.style.paddingBottom = 0;
            target.style.marginTop = 0;
            target.style.marginBottom = 0;

            window.setTimeout(() => {
                target.style.display = 'none';
                target.style.removeProperty('height');
                target.style.removeProperty('padding-top');
                target.style.removeProperty('padding-bottom');
                target.style.removeProperty('margin-top');
                target.style.removeProperty('margin-bottom');
                target.style.removeProperty('overflow');
                target.style.removeProperty('transition-duration');
                target.style.removeProperty('transition-property');
            }, duration);
        };

        /* SLIDE DOWN */

        let slideDown = (target, duration = 500) => {
            target.style.removeProperty('display');
            let display = window.getComputedStyle(target).display;
            if (display === 'none') display = 'block';
            target.style.display = display;
            let height = target.offsetHeight;
            target.style.overflow = 'hidden';
            target.style.height = 0;
            target.style.paddingTop = 0;
            target.style.paddingBottom = 0;
            target.style.marginTop = 0;
            target.style.marginBottom = 0;
            target.offsetHeight;
            target.style.boxSizing = 'border-box';
            target.style.transitionProperty = 'height, margin, padding';
            target.style.transitionDuration = duration + 'ms';
            target.style.height = height + 'px';
            target.style.removeProperty('padding-top');
            target.style.removeProperty('padding-bottom');
            target.style.removeProperty('margin-top');
            target.style.removeProperty('margin-bottom');

            window.setTimeout(() => {
                target.style.removeProperty('height');
                target.style.removeProperty('overflow');
                target.style.removeProperty('transition-duration');
                target.style.removeProperty('transition-property');
            }, duration);
        };

        /* TOOGLE */

        var slideToggle = (target, duration = 500) => {
            if (window.getComputedStyle(target).display === 'none') {
                return slideDown(target, duration);
            } else {
                return slideUp(target, duration);
            }
        };

        $('.schema-faq-section').on('click', function () {
            $(this).toggleClass('opened');
            let accordionItem = this.closest('.schema-faq-section');
            let content = accordionItem.querySelector('.schema-faq-answer');
            slideToggle(content, 300);
        });

        $('.rally-program-days__section').on('click', function () {
            $(this).toggleClass('opened');
            let content = this.querySelector('.rally-program-days__answer');
            slideToggle(content, 300);
        });

        let search = document.querySelector('#faq-search');

        if (search) {
            let typingTimer;
            search.addEventListener('input', function (e) {
                let value = this.value;
                if (value.length > 2 || value.length === 0) {
                    var keyCode = e.keyCode || e.which;
                    if (keyCode === 13) {
                        e.preventDefault();
                        return false;
                    }
                    clearTimeout(typingTimer);
                    typingTimer = setTimeout(searchFaq(value), 800);
                }
            });

            function searchFaq(searchQuery) {
                let faqSections = document.querySelectorAll('.schema-faq-section');
                faqSections.forEach(function (section) {
                    let answer = section.querySelector('.schema-faq-answer');
                    let question = section.querySelector('.schema-faq-question');

                    if (question.textContent.search(searchQuery) > -1 || answer.textContent.search(searchQuery) > -1) {
                        section.classList.add('faq-result');
                        section.classList.remove('faq-hide');
                        if (!section.classList.contains('opened')) {
                            slideDown(answer, 300);
                            section.classList.add('opened');
                        }
                    } else {
                        section.classList.remove('faq-result');
                        section.classList.remove('opened');
                        section.classList.add('faq-hide');
                        slideUp(answer, 300);
                    }

                    if (searchQuery.length === 0) {
                        slideUp(answer, 300);
                        section.classList.remove('faq-result');
                        section.classList.remove('opened');
                        section.classList.remove('faq-hide');
                    }
                });
            }
        }
    };

    mena.main.loadAssets = function () {
        if ($('.hero-slider__swiper').length > 0 || $('.archive-posts__slider').length > 0 || $('.testimonial-slider__slider').length > 0 || $('.upcoming-rallies__slider').length > 0 || $('.home .latest-posts__slider').length > 0) {
            loadjs([menaObject.assets + '/js/swiper.min.js', menaObject.assets + '/css/swiper.css'], function () {
                mena.main.heroSliderSwiper();
                mena.main.archiveSlider();
                mena.main.testimonialSlider();
                mena.main.upcomingRalliesSlider();
                mena.main.upcomingVideoSlider();
                mena.main.latestPostsSlider();
            });
        }
    };

    // Hero slider
    mena.main.heroSliderSwiper = function () {
        let heroSwiper = new Swiper('.hero-slider__swiper', {
            loop                : false,
            slidesPerView       : 1,
            effect              : 'fade',
            fadeEffect          : {
                crossFade: true,
            },
            pagination          : {
                el       : '.hero-slider__pagination',
                clickable: true,
            },
            autoplay            : {
                delay: 4000,
            },
            disableOnInteraction: false,
        });
    };

    // Archive slider
    mena.main.archiveSlider = function () {
        let archiveSwiper = new Swiper('.archive-posts__slider', {
            slidesPerView: 1,
            effect       : 'fade',
            fadeEffect   : {
                crossFade: true,
            },
            // autoplay            : {
            //     delay: 5000,
            // },
            // disableOnInteraction: false,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el       : '.swiper-pagination',
                clickable: true,
            },
        });
    };

    // Testimonial slider
    mena.main.testimonialSlider = function () {
        let testimonialSwiper = new Swiper('.testimonial-slider__slider', {
            loop         : true,
            slidesPerView: 1,
            effect       : 'fade',
            fadeEffect   : {
                crossFade: true,
            },
            autoplay     : {
                delay: 5000,
            },
            pagination   : {
                el       : '.swiper-pagination',
                clickable: true,
            },
        });
    };

    mena.main.upcomingVideoSlider = function () {
        let testimonialSwiper = new Swiper('.video-slider__slider', {
            slidesPerView: 1.1,
            spaceBetween: 18,
            navigation: {
                nextEl: '.video-slider__next',
                prevEl: '.video-slider__prev',
            },
            scrollbar: {
                el: '.video-slider__scrollbar',
                draggable: true,
            },
            breakpoints: {
                550: {
                    slidesPerView: 2,
                },
                1023: {
                    slidesPerView: 3,
                },
            },
        });

        // Add click event listener to images and SVG icons
        $('.video-slider__slider img, .video-slider__info-svg').on('click', function (e) {
            e.stopPropagation();

            let $this = $(this);
            let $container = $this.closest('.swiper-slide');
            let iframeSrc = $container.find('iframe').attr('src');

            $('body').css('overflow', 'hidden');

            if (iframeSrc) {
                let dialog = document.getElementById('videoDialog');
                dialog.querySelector('.video-slider__iframe').setAttribute('src', iframeSrc);
                dialog.showModal();
            }
        });

        // Click event to close dialog
        let dialog = document.getElementById('videoDialog');
        dialog.addEventListener('click', function (event) {
            if (event.target === this || event.target.getAttribute('data-close')) {
                let iframe = this.querySelector('.video-slider__iframe');
                iframe.removeAttribute('src'); // Remove src attribute from iframe
                this.close();
                $('body').css('overflow', 'auto');
            }
        });

        // Close modal if clicked outside of it
        dialog.addEventListener('click', function (event) {
            if (!event.target.closest('.video-slider__dialog-content')) {
                let iframe = this.querySelector('.video-slider__iframe');
                iframe.removeAttribute('src'); // Remove src attribute from iframe
                dialog.close();
                $('body').css('overflow', 'auto');
            }
        });
    };

// Upcoming rallies slider
mena.main.upcomingRalliesSlider = function () {
    let testimonialSwiper = new Swiper('.upcoming-rallies__slider', {
        slidesPerView: 1,
        spaceBetween : 18,
        navigation   : {
            nextEl: '.upcoming-rallies__next',
            prevEl: '.upcoming-rallies__prev',
        },
        scrollbar    : {
            el       : '.upcoming-rallies__scrollbar',
            draggable: true,
        },
        breakpoints  : {
            439 : {
                slidesPerView: 2,
            },
            1023: {
                slidesPerView: 3,
            },
        },
    });
};

    // Upcoming rallies slider
    mena.main.latestPostsSlider = function () {
        let postsSwiper = new Swiper('.latest-posts__slider', {
            loop          : true,
            slidesPerView : 1.1,
            spaceBetween  : 17,
            scrollbar     : {
                el       : '.latest-posts__scrollbar',
                draggable: true,
            },
            breakpoints   : {
                439 : {
                    slidesPerView: 2,
                },
                640 : {
                    slidesPerView: 3,
                },
                1023: {
                    slidesPerView: 4,
                },
            },
        });
    };

    mena.main.getNews = function () {
        let pageNumber;
        listenPagination();

        function listenPagination() {
            $('.all-news .page-numbers:not(.dots)').on('click', function (ev) {
                ev.preventDefault();
                if (!$(this).hasClass('current')) {
                    pageNumber = $(this).text();

                    if ($(this).hasClass('next')) {
                        pageNumber = parseInt($(this).siblings('.current').text()) + 1;
                    }

                    if ($(this).hasClass('prev')) {
                        pageNumber = parseInt($(this).siblings('.current').text()) - 1;
                    }
                    getNews(pageNumber);
                }
            });
        }

        function getNews(paginationNumber) {
            $.ajax({
                type    : 'POST',
                url     : menaObject.ajaxurl,
                dataType: 'html', // add data type
                data    : {
                    action          : 'get_blog_posts',
                    paginationNumber: paginationNumber,
                },

                beforeSend: function () {
                    $('.all-archive-posts__grid').addClass('loading');
                },

                success: function (response) {
                    $('.pagination').remove();
                    document.querySelector('.all-archive-posts__grid').scrollIntoView({
                        behavior: 'smooth',
                        block   : 'start',
                        inline  : 'nearest',
                    });
                    $('.all-archive-posts__grid').html(response);
                    $('.all-archive-posts__grid').removeClass('loading');
                    var pagination = $('.pagination').detach();
                    $('.pagination-holder').append(pagination);
                    listenPagination();
                },
            });
        }
    };

    mena.main.animations = function () {
        gsap.registerPlugin('scrollTrigger');
        ScrollTrigger.batch('[data-animation=\'fade-up\']', {
            interval: 0.1, // time window (in seconds) for batching to occur.
            batchMax: 10,
            onEnter : (batch) =>
                gsap.to(batch, {
                    opacity  : 1,
                    y        : 0,
                    stagger  : { each: 0.1 },
                    ease     : 'power2.inOut',
                    overwrite: true,
                    duration : 0.5,
                }),
            start   : 'top 95%',
            end     : 'top top',
        });

        ScrollTrigger.create({
            trigger    : '.rally-navigation',
            start      : 'bottom 20%',
            endTrigger : '.footer',
            toggleClass: {
                targets  : '.rally-navigation__icon img',
                className: 'active',
            },
        });

        if (document.querySelector('.single-rally') == null) {
            ScrollTrigger.create({
                trigger : '.gallery__load-more',
                start   : 'top 120%',
                onToggle: (self) => {
                    $('.gallery__load-more').trigger('click');
                },
            });
        }
    };

    mena.main.svg4everybody = function () {
        svg4everybody();
    };

    mena.main.mailchimp = function () {
        if ($('.js-mailchimp').length > 0) {
            loadjs(menaObject.assets + '/js/mailchimp-validate.js', function () {
                window.fnames = new Array();
                window.ftypes = new Array();
                fnames[0] = 'EMAIL';
                ftypes[0] = 'email';
            });
        }
    };

    mena.main.debounce = function (func, wait, immediate) {
        var timeout;
        return function () {
            var context = this,
                args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    };

    mena.main.stickyHeader = function () {
        var stickyHeader = function () {
            var header = $('#header');
            $('#content').css('margin-top', header.outerHeight());

            if ($(window).scrollTop() > 1) {
                header.addClass('sticky');
            } else {
                header.removeClass('sticky');
            }
        };

        window.addEventListener('load', stickyHeader);
        window.addEventListener('scroll', mena.main.debounce(stickyHeader, 150));
        window.addEventListener('resize', mena.main.debounce(stickyHeader, 150));
    };

    mena.main.mobileMenu = function () {
        var respMenu = $('.resp-menu');
        var button = $('.js-menu-toggle');
        var body = $('body');

        button.click(function (e) {
            body.toggleClass('menu-open');
            button.toggleClass('menu-toggle--active');
        });

        /* Adding submenu arrows to the responsive navigation */
        respMenu.find('.sub-menu').each(function () {
            $(this).parent().append('<a class="submenu-button" href="javascript:void(0)"><svg class="arrow-down" width="15px" height="8px"><polygon fill="#fff" points="15.002,0 7.501,7.501 0,0 "></polygon></svg></a>');
        });

        $('.submenu-button').click(function () {
            $('.sub-menu').not($(this).siblings()).slideUp(300);
            $(this).prev('.sub-menu').slideToggle(100);
            $(this).toggleClass('rotate-arrow');
        });
    };

    mena.main.news = function () {
        function getNews(category) {
            $.ajax({
                type    : 'POST',
                url     : menaObject.ajaxurl,
                dataType: 'html',
                data    : {
                    action  : 'get_news',
                    category: category,
                },

                beforeSend: function () {
                    $('.archive-posts__grid').addClass('loading');
                },

                success: function (response) {
                    $('.archive-posts__grid').html(response);
                    $('.archive-posts__grid').removeClass('loading');

                    let posts = document.querySelector('.archive-posts__grid');
                },
            });
        }

        var category;

        $('.filters__item').on('click', function (ev) {
            if (!$(this).hasClass('active')) {
                $('.filters__item.active').removeClass('active');
                $(this).addClass('active');
                category = $('.filters__item.active').data('category');
                getNews(category);
            }
        });
    };

    mena.main.allRallies = function () {
        let pageNumber;
        listenPagination();

        function listenPagination() {
            $('.rallies .page-numbers').on('click', function (ev) {
                ev.preventDefault();
                if (!$(this).hasClass('current')) {
                    pageNumber = $(this).text();

                    if ($(this).hasClass('next')) {
                        pageNumber = parseInt($(this).siblings('.current').text()) + 1;
                    }

                    if ($(this).hasClass('prev')) {
                        pageNumber = parseInt($(this).siblings('.current').text()) - 1;
                    }
                    let category = $('.rallies').data('category');
                    getRallies(pageNumber, category);
                }
            });
        }

        function getRallies(paginationNumber, category) {
            $.ajax({
                type    : 'GET',
                url     : menaObject.ajaxurl,
                dataType: 'html', // add data type
                data    : {
                    action          : 'get_blog_posts',
                    paginationNumber: paginationNumber,
                    category        : category,
                },

                beforeSend: function () {
                    $('.rallies__items').addClass('loading');
                },

                success: function (response) {
                    $('.pagination').remove();
                    document.querySelector('.rallies__items').scrollIntoView({
                        behavior: 'smooth',
                        block   : 'start',
                        inline  : 'nearest',
                    });
                    $('.rallies__items').html(response);
                    $('.rallies__items').removeClass('loading');
                    var pagination = $('.pagination').detach();
                    $('.pagination-holder').append(pagination);
                    listenPagination();
                },
            });
        }
    };

    mena.main.gallery = function () {
        function getImages(postIds, viaLoadMore, continent = 'all', type = 'all', year = 'all') {
            $.ajax({
                type    : 'GET',
                url     : menaObject.ajaxurl,
                dataType: 'html',
                data    : {
                    action   : 'get_gallery',
                    postIds  : postIds,
                    continent: continent,
                    type     : type,
                    year     : year,
                },

                beforeSend: function () {
                    if (!viaLoadMore == 1) {
                        $('.gallery__images').addClass('loading');
                    }
                },

                success: function (response) {
                    if (response) {
                        response = JSON.parse(response);
                    }
                    if (viaLoadMore == 1) {
                        $('.gallery__images').append(response.html);
                    } else {
                        $('.gallery__images').html(response.html);
                        $('.gallery__images').removeClass('loading');
                        let posts = document.querySelector('.gallery__images');
                        //posts.scrollIntoView();
                    }
                    if (response.show_load_more != '1') {
                        $('.gallery__load-more').hide();
                    } else {
                        $('.gallery__load-more').show();
                    }

                    $('.gallery__filters-item').removeClass('open');

                    ScrollTrigger.batch('[data-animation=\'fade-up\']', {
                        interval: 0.1, // time window (in seconds) for batching to occur.
                        batchMax: 10,
                        onEnter : (batch) =>
                            gsap.to(batch, {
                                opacity  : 1,
                                y        : 0,
                                stagger  : { each: 0.1 },
                                ease     : 'power2.inOut',
                                overwrite: true,
                                duration : 0.5,
                            }),
                        start   : 'top 95%',
                        end     : 'top top',
                    });

                    ScrollTrigger.refresh();
                },
            });
        }

        $('.gallery__filters-filter').on('click', function (ev) {
            if ($(this).hasClass('js-filter-all')) {
                console.log('all');
                if (!$('.js-filter-all').hasClass('font-bold')) {
                    $('.js-filter-all').toggleClass('font-bold');
                }
                $('.gallery__filters-item').removeClass('active');
                $('.gallery__filters-filter.active').removeClass('active');

                $('.gallery__filters-item[data-name="continent"] .gallery__labels').text($('.gallery__filters-item[data-name="continent"] .gallery__filters-filter:first-child').text());
                $('.gallery__filters-item[data-name="type"] .gallery__labels').text($('.gallery__filters-item[data-name="type"] .gallery__filters-filter:first-child').text());
                $('.gallery__filters-item[data-name="year"] .gallery__labels').text($('.gallery__filters-item[data-name="year"] .gallery__filters-filter:first-child').text());
                getImages('', 0, 'all', 'all', 'all');
            } else {
                if (!$(this).hasClass('active')) {
                    $('.js-filter-all').removeClass('font-bold');
                    $(this).siblings('.gallery__filters-filter').removeClass('active');

                    $(this).addClass('active');
                    $(this).closest('.gallery__filters-item').addClass('active');

                    let continent = $('.gallery__filters-item[data-name="continent"] .gallery__filters-filter.active').data('value');
                    let type = $('.gallery__filters-item[data-name="type"] .gallery__filters-filter.active').data('value');
                    let year = $('.gallery__filters-item[data-name="year"] .gallery__filters-filter.active').data('value');

                    if (continent == undefined) {
                        continent = 'all';
                    }
                    if (type == undefined) {
                        type = 'all';
                    }
                    if (year == undefined) {
                        year = 'all';
                    }

                    getImages('', 0, continent, type, year);

                    if ($('.gallery__filters-item[data-name="continent"]').hasClass('active')) {
                        $('.gallery__filters-item[data-name="continent"].active .gallery__labels').text($('.gallery__filters-item[data-name="continent"] .gallery__filters-filter.active').text());
                    }
                    if ($('.gallery__filters-item[data-name="type"]').hasClass('active')) {
                        $('.gallery__filters-item[data-name="type"].active .gallery__labels').text($('.gallery__filters-item[data-name="type"] .gallery__filters-filter.active').text());
                    }
                    if ($('.gallery__filters-item[data-name="year"]').hasClass('active')) {
                        $('.gallery__filters-item[data-name="year"].active .gallery__labels').text($('.gallery__filters-item[data-name="year"] .gallery__filters-filter.active').text());
                    }
                }
            }
        });

        // load more button
        $('.gallery__load-more').on('click', function (ev) {
            if ($('.gallery__images > a:last-child').hasClass('g-10')) {
                // Count number of unique post ids
                let allImages = Array.from(document.querySelectorAll('.gallery__images > a'));

                // Store post ids in array
                let postIds = allImages.map(function (image) {
                    return image.dataset.postId;
                });

                let continent = $('.gallery__filters-item[data-name="continent"] .gallery__filters-filter.active').data('value');
                let type = $('.gallery__filters-item[data-name="type"] .gallery__filters-filter.active').data('value');
                let year = $('.gallery__filters-item[data-name="year"] .gallery__filters-filter.active').data('value');

                if (continent == undefined) {
                    continent = 'all';
                }
                if (type == undefined) {
                    type = 'all';
                }
                if (year == undefined) {
                    year = 'all';
                }

                getImages(postIds, 1, continent, type, year);
            }
        });
    };

    mena.main.filter = function () {
        $('.gallery__filters-item').on('click', function () {
            if ($('.gallery__filters-item').hasClass('open')) {
                if ($(this).hasClass('open')) {
                    $('.gallery__filters-item').removeClass('open');
                } else {
                    $('.gallery__filters-item').removeClass('open');
                    $(this).toggleClass('open');
                }
            } else {
                $(this).toggleClass('open');
            }
        });
    };

    mena.main.copyToClipboard = function () {
        $('.js-copy-url-to-clipboard').on('click', function (event) {
            if (!$('.social-share-list__icon--clipboard').hasClass('active')) {
                $('.social-share-list__icon--clipboard').addClass('active');

                setTimeout(function () {
                    $('.social-share-list__icon--clipboard').removeClass('active');
                }, 5000);
            }
            event.preventDefault();
            copy_url();
        });

        function copy_url() {
            var dummy = document.createElement('input'),
                text = window.location.href;

            document.body.appendChild(dummy);
            dummy.value = text;
            dummy.select();
            document.execCommand('copy');
            document.body.removeChild(dummy);
        }
    };

    return mena.main.init();
})($);
